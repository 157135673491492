// theme.js
import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === "dark" ? "var(--background-color-dark)" : "var(--background-color-light)",
        color: props.colorMode === "dark" ? "var(--text-color-dark)" : "var(--text-color-light)",
        fontFamily: "'Roboto', sans-serif",
      },
    }),
  },
});

export default theme;