import React, { useState, useEffect, useCallback, lazy, Suspense, useRef } from "react";
import {
  ChakraProvider,
  Box,
  Flex,
  ColorModeScript,
  Button,
  Heading,
  Text,
  Spinner,
  VStack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { db, auth, signInAnonymously } from "./firebase";
import { 
  doc, 
  getDoc, 
  onSnapshot, 
  collection, 
  query, 
  orderBy, 
  limit, 
  getDocs, 
  setDoc 
} from "firebase/firestore"; // Removed 'updateDoc' as it is not used
import moment from "moment-timezone";
import "./App.css";
import theme from "./theme";
import { motion, AnimatePresence } from "framer-motion";
import styled, { keyframes } from "styled-components";
import ReactConfetti from "react-confetti";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import { GiCrown } from "react-icons/gi";

const DudeOfTheDay = lazy(() => import("./DudeOfTheDay"));
const PreviousWinners = lazy(() => import("./PreviousWinners"));

// Add this helper function
const trackEvent = (action, category, label, value) => {
  if (window.gtag) {
    window.gtag('event', action, {
      'event_category': category,
      'event_label': label,
      'value': value
    });
  }
};

const HighlightedDude = ({ dude }) => {
  const imageSource = dude.imageUrl || dude.imageURL;
  const [showConfetti, setShowConfetti] = useState(false);

  const handleImageClick = useCallback(() => {
    setShowConfetti(true);
    setTimeout(() => setShowConfetti(false), 5000); // Hide confetti after 5 seconds
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      {showConfetti && <ReactConfetti />}
      <Box
        className="highlighted-dude"
        bg="rgba(45, 55, 72, 0.9)"
        backdropFilter="blur(15px)"
        p={{ base: 4, md: 8 }}
        borderRadius="3xl"
        boxShadow="0 8px 32px rgba(0, 0, 0, 0.15)"
        border="2px solid rgba(255, 255, 255, 0.1)"
        transition="all 0.3s ease-in-out"
        _hover={{
          transform: "translateY(-5px)",
          boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
        }}
        role="article"
        aria-label="Highlighted Dude of the Day"
      >
        <Heading as="h3" size="xl" textAlign="center" mb={6} color="teal.300">
          {dude.name}
        </Heading>
        <Box display="flex" justifyContent="center" mb={6} position="relative">
          <Box
            width="280px"
            height="280px"
            borderRadius="lg"
            overflow="hidden"
            boxShadow="0 4px 20px rgba(0, 0, 0, 0.2)"
            border="4px solid gold"
            position="relative"
            onClick={handleImageClick}
            cursor="pointer"
          >
            {imageSource ? (
              <motion.img
                src={imageSource}
                alt={dude.name}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              />
            ) : (
              <Flex
                width="100%"
                height="100%"
                alignItems="center"
                justifyContent="center"
                bg="gray.700"
              >
                <Text color="gray.400">No image available</Text>
              </Flex>
            )}
          </Box>
          <Box
            position="absolute"
            top="-40px"
            left="50%"
            transform="translateX(-50%)"
            color="gold"
            fontSize="80px"
          >
            <motion.div
              initial={{ y: 0 }}
              animate={{ y: [-5, 5, -5] }}
              transition={{ repeat: Infinity, duration: 2 }}
            >
              <GiCrown />
            </motion.div>
          </Box>
        </Box>
        <Box className="fun-fact-container" mt={6} mb={6}>
          <Text className="fun-fact-label" fontSize="sm" fontWeight="bold" color="teal.300" mb={2}>
            Fun Fact:
          </Text>
          <Text className="fun-fact-text" fontSize="md" color="gray.300" fontStyle="italic">
            "{dude.funFact}"
          </Text>
        </Box>
        <Text textAlign="center" fontWeight="bold" fontSize="xl" color="teal.200">
          Votes: {dude.votes} 
          <Text as="span" fontSize="md" color="gray.400" ml={2}>
            ({dude.percentage.toFixed(1)}%)
          </Text>
        </Text>
      </Box>
    </motion.div>
  );
};

// Enhanced beer filling animation with more realistic gradient
const fillBeer = keyframes`
  0% { background-position: 0 100%; }
  100% { background-position: 0 0%; }
`;

// Bubble animation with more variation
const bubbleRise = keyframes`
  0% { transform: translateY(0) scale(1); opacity: 0; }
  50% { opacity: 1; }
  100% { transform: translateY(-150px) scale(1.5); opacity: 0; }
`;

// Wobble animation for the text
const wobble = keyframes`
  0%, 100% { transform: translateX(0) rotate(0); }
  25% { transform: translateX(-5px) rotate(-1deg); }
  75% { transform: translateX(5px) rotate(1deg); }
`;

const BeerContainer = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 10px;
`;

const StyledHeading = styled(motion.h1)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 4rem;
  text-align: center;
  background: linear-gradient(
    to bottom,
    #f6e9d1 0%,
    #f6e9d1 ${props => 100 - props.fillLevel}%,
    #f28e1c ${props => 100 - props.fillLevel}%,
    #e9b213 ${props => 100 - props.fillLevel + 5}%,
    #eac81e ${props => 100 - props.fillLevel + 10}%,
    #ffd700 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-size: 100% 300%;
  animation: ${fillBeer} 10s linear infinite, ${wobble} 3s ease-in-out infinite;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 1rem;
  position: relative;
  z-index: 1;
  padding: 0.5rem 1rem;
`;

const Foam = styled.div`
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, #ffffff, #f7f7f7, #e0e0e0);
  border-radius: 25px 25px 0 0;
  opacity: ${props => props.fillLevel >= 100 ? 1 : 0};
  transition: opacity 0.5s ease-out, top 0.5s ease-out;
  z-index: 2;
  animation: ${props => props.fillLevel >= 100 ? 'foamBounce 2s infinite' : 'none'}; 

  @keyframes foamBounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
  }
`;

const Bubble = styled.div`
  position: absolute;
  bottom: 0;
  left: ${props => props.left}%;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  animation: ${bubbleRise} ${props => props.duration}s linear infinite;
  animation-delay: ${props => props.delay}s;
`;

const HeaderContainer = styled(motion.div)`
  background: rgba(var(--card-bg-rgb), 0.8);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(var(--shadow-color-rgb), 0.2);
  border: 1px solid rgba(var(--primary-color-rgb), 0.2);
  padding: 3rem 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NodudeMessage = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background: rgba(var(--accent-color-rgb), 0.1);
  border-radius: 16px;
  margin-top: 2rem;
`;

const QuoteIcon = styled(motion.div)`
  color: var(--accent-color);
  font-size: 2rem;
  margin: 0 1rem;
  opacity: 0.6;
`;

const moveBackground = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const AnimatedBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    -45deg,
    #ee7752,
    #e73c7e,
    #23a6d5,
    #23d5ab
  );
  background-size: 400% 400%;
  animation: ${moveBackground} 15s ease infinite;
  z-index: -1;
`;

const HeaderBox = styled(Box)`
  background: rgba(var(--card-bg-rgb), 0.8);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(var(--shadow-color-rgb), 0.2);
  border: 1px solid rgba(var(--primary-color-rgb), 0.2);
  padding: 2rem;
  margin-bottom: 2rem;
  width: 100%;
`;

const InteractiveHeading = styled(Heading)`
  font-size: 2.5rem; // Increase font size for prominence
  color: white; // Set text color to white
  text-align: center;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3); // Add a subtle shadow for depth
  transition: transform 0.3s ease, text-shadow 0.3s ease; // Smooth transition for hover effects
  margin-bottom: 1rem;

  &:hover {
    transform: scale(1.05); // Slightly enlarge the text on hover
    text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); // Enhance shadow on hover
  }
`;

const TopDudes = ({ dudes }) => {
  return (
    <Box>
     
      <VStack spacing={2} align="stretch">
        {dudes.map((dude, index) => (
          <Box key={dude.id} p={2} bg="gray.700" borderRadius="md">
            <Text>
              {index + 1}. {dude.name} - Votes: {dude.votes}
            </Text>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

// Create a Motion-enabled Chakra Button
const MotionButton = motion(Button);

const App = () => {
  const [showWinners, setShowWinners] = useState(false);
  const [dudes, setDudes] = useState([]);
  const [currentDude, setCurrentDude] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const hasShownWelcomeToast = useRef(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [beerFillLevel, setBeerFillLevel] = useState(0);
  const [bubbles, setBubbles] = useState([]);

  const fetchTomorrowsDudes = useCallback(async () => {
    try {
      const tomorrow = moment()
        .tz("America/New_York")
        .add(1, "days")
        .format("YYYY-MM-DD");
      const tomorrowDudesRef = doc(db, "dailyDudes", tomorrow);
      const tomorrowDudesSnap = await getDoc(tomorrowDudesRef);

      if (tomorrowDudesSnap.exists()) {
        const data = tomorrowDudesSnap.data();
        const dudeIds = data.dudes || [];
        console.log("Fetched dude IDs for tomorrow:", dudeIds);

        const dudePromises = dudeIds.map((id) => getDoc(doc(db, "dudes", id)));
        const dudeDocs = await Promise.all(dudePromises);
        
        const dudesData = dudeDocs
          .map((doc) => {
            if (doc.exists()) {
              return {
                id: doc.id,
                ...doc.data(),
                imageUrl: doc.data().imageUrl || doc.data().imageURL,
              };
            }
            return null;
          })
          .filter((dude) => dude !== null);

        console.log("Fetched dudes data for tomorrow:", dudesData);
        setDudes(dudesData);
      } else {
        console.log("No dudes found for tomorrow");
        setDudes([]);
      }
    } catch (error) {
      console.error("Error fetching tomorrow's dudes:", error);
      setError("Error fetching tomorrow's dudes. Please try again later.");
    }
  }, []);

  const checkCurrentDude = useCallback(async () => {
    setIsLoading(true);
    try {
      // Query the winners collection for the most recent document
      const winnersRef = collection(db, "winners");
      const q = query(winnersRef, orderBy("date", "desc"), limit(1));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const winnerDoc = querySnapshot.docs[0];
        const data = winnerDoc.data();
        const totalVotes = data.totalVotes || 0;
        const dudeVotes = data.votes || 0;
        const percentage = totalVotes > 0 ? (dudeVotes / totalVotes) * 100 : 0;

        setCurrentDude({
          ...data,
          percentage: parseFloat(percentage.toFixed(2)),
        });
      } else {
        console.log("No winners found");
      }

      await fetchTomorrowsDudes();
      setIsLoading(false);
    } catch (error) {
      console.error("Error checking current dude:", error);
      setError("Error checking current dude. Please try again later.");
      setIsLoading(false);
    }
  }, [fetchTomorrowsDudes]); // Ensure all dependencies are included

  useEffect(() => {
    const signInUser = async () => {
      try {
        await signInAnonymously(auth);
        console.log("User signed in anonymously");
        if (!hasShownWelcomeToast.current) {
          toast({
            title: "Welcome!",
            description: "You're now ready to vote for your favorite dude.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          hasShownWelcomeToast.current = true;
        }
      } catch (error) {
        console.error("Anonymous authentication failed:", error);
        setError("Anonymous authentication failed. Please try again later.");
      }
    };

    signInUser();
    checkCurrentDude();

    // Set up real-time listener for vote updates
    const today = moment().tz("America/New_York").format("YYYY-MM-DD");
    const unsubscribe = onSnapshot(doc(db, "winners", today), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const totalVotes = data.totalVotes || 0;
        const dudeVotes = data.votes || 0;
        const percentage = totalVotes > 0 ? (dudeVotes / totalVotes) * 100 : 0;

        setCurrentDude({
          ...data,
          percentage: parseFloat(percentage.toFixed(2)),
        });
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 5000);
      }
    });

    return () => unsubscribe();
  }, [checkCurrentDude, toast]);

  // Track when a user views previous winners
  const handleShowWinners = () => {
    setShowWinners((prev) => {
      const newState = !prev;
      if (newState) {
        trackEvent('view', 'previous_winners', 'Viewed Previous Winners');
      }
      return newState;
    });
  };

  // Track when the modal is opened
  useEffect(() => {
    if (isOpen) {
      trackEvent('open', 'modal', 'About Modal Opened');
    }
  }, [isOpen]);

  useEffect(() => {
    const fillInterval = setInterval(() => {
      setBeerFillLevel(prevLevel => {
        const newLevel = prevLevel + 1;
        return newLevel > 100 ? 0 : newLevel;
      });
    }, 100);

    return () => clearInterval(fillInterval);
  }, []);

  // Generate bubbles
  useEffect(() => {
    const newBubbles = Array.from({ length: 30 }, (_, i) => ({
      id: i,
      left: Math.random() * 100,
      size: 3 + Math.random() * 7, // Varying sizes for realism
      duration: 2 + Math.random() * 5, // Varying speeds
      delay: Math.random() * 3
    }));
    setBubbles(newBubbles);
  }, []);

  const handleVote = async (dudeId) => {
    try {
      const userId = auth.currentUser.uid; // Assuming you have user authentication
      const today = moment().tz("America/New_York").format("YYYY-MM-DD");
      const voteRef = doc(db, "votes", `${userId}_${today}`);

      const voteSnap = await getDoc(voteRef);

      if (voteSnap.exists()) {
        toast({
          title: "Already Voted",
          description: "You have already voted today. Please come back tomorrow!",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await setDoc(voteRef, {
          userId,
          dudeId,
          date: today,
        });

        toast({
          title: "Vote Cast",
          description: "Thank you for voting!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Optionally, update the vote count for the dude
        // const dudeRef = doc(db, "dudes", dudeId);
        // await updateDoc(dudeRef, {
        //   votes: increment(1),
        // });
      }
    } catch (error) {
      console.error("Error casting vote:", error);
      toast({
        title: "Error",
        description: "There was an error casting your vote. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <Box className="App" position="relative" minHeight="100vh">
        <div className="lava-lamp-background"></div>
        <AnimatedBackground />
        {showConfetti && <ReactConfetti />}
        
        <VStack spacing={8} align="stretch" position="relative" zIndex="docked">
          <HeaderContainer
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <BeerContainer>
              <StyledHeading
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={onOpen}
                cursor="pointer"
                aria-label="About Dude of the Day"
                role="button"
                fillLevel={beerFillLevel}
              >
                Dude of the Day
              </StyledHeading>
              <Foam fillLevel={beerFillLevel} />
              {bubbles.map(bubble => (
                <Bubble
                  key={bubble.id}
                  left={bubble.left}
                  size={bubble.size}
                  duration={bubble.duration}
                  delay={bubble.delay}
                />
              ))}
            </BeerContainer>
            {currentDude ? (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <HighlightedDude dude={currentDude} />
                <Text textAlign="center" mt={4} color="var(--text-color)" fontSize="lg">
                  {/* Add any additional text or information here */}
                </Text>
              </motion.div>
            ) : (
              <NodudeMessage
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <QuoteIcon as={FaQuoteLeft} />
                <Text fontSize="xl" fontWeight="bold" color="var(--text-color)">
                  No Dude Selected for Today
                </Text>
                <QuoteIcon as={FaQuoteRight} />
              </NodudeMessage>
            )}
          </HeaderContainer>
          {isLoading ? (
            <Flex justify="center" align="center" height="50vh">
              <Spinner 
                size="xl" 
                color="teal.500" 
                thickness="4px" 
                speed="0.65s" 
              />
            </Flex>
          ) : (
            <Suspense 
              fallback={
                <Spinner 
                  size="xl" 
                  color="teal.500" 
                  thickness="4px" 
                  speed="0.65s" 
                />
              }
            >
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 50 }}
                  transition={{ duration: 0.5 }}
                >
                  <HeaderBox>
                    <InteractiveHeading as="h2" className="pick-tomorrow-title">
                      Pick Tomorrow's Dude of the Day
                    </InteractiveHeading>
                  </HeaderBox>
                  <DudeOfTheDay 
                    dudes={dudes} 
                    onVote={(dudeName) => {
                      trackEvent('vote', 'dude', `Voted for ${dudeName}`);
                      handleVote(dudeName); // Call the handleVote function when a vote is cast
                    }}
                  />
                  <TopDudes dudes={dudes} />
                  <Flex justify="center" mt={8}>
                    <MotionButton
                      onClick={handleShowWinners}
                      variant="outline"
                      size="lg"
                      leftIcon={<StarIcon />}
                      bgGradient="linear(to-r, teal.400, teal.600)"
                      color="white"
                      boxShadow="lg"
                      whileHover={{
                        scale: 1.05,
                        bgGradient: "linear(to-r, teal.500, teal.700)",
                        boxShadow: "2xl",
                      }}
                      whileTap={{ scale: 0.95 }}
                      transition={{ type: "spring", stiffness: 300 }}
                    >
                      {showWinners ? "Hide Previous Winners" : "Show Previous Winners"}
                    </MotionButton>
                  </Flex>
                  {showWinners && <PreviousWinners />}
                </motion.div>
              </AnimatePresence>
            </Suspense>
          )}
          {error && (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <Text color="red.500" textAlign="center" mt={4}>{error}</Text>
              </motion.div>
            </AnimatePresence>
          )}
        </VStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg="white" color="var(--text-color)">
          <ModalHeader color="black">About Dude of the Day</ModalHeader>
          <ModalCloseButton color="black" />
          <ModalBody>
            <Text color="black">
              Welcome to Dude of the Day. This is a simple daily voting platform where users select
              the most interesting or notable person of the day.
            </Text>
            <Text mt={4} color="black">
              Cast your vote for tomorrow's featured individual, view past winners, and participate
              in this community-driven selection process. The platform aims to highlight diverse
              personalities and achievements.
            </Text>
            <Badge colorScheme="teal" mt={4}>Updates in progress</Badge>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default App;
